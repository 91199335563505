/* You can add global styles to this file, and also import other style files */

root {
  --rosa-oficial: #CE1F64;
}

.mat-fab .mat-button-wrapper {
  padding: 0px;
}

.header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 10;
}

/* portada */

.portada {
  background: rgb(180, 180, 180);   height: 500px; width: 100%;
}

.portada img {
  width: 100%; height: 100%; object-fit:cover;
}

.portada-padding {
  top: 400px
}

  /* portada */

.opacity-half {
  opacity: .5;
}

.gray-background {
  background: rgb(221, 221, 221);
}

.artesania-theme {
  background: #367B92 !important;
  background-color: #367B92 !important;
}

.arte-digital-theme {
  background: #731E7D !important;
  background-color: #731E7D !important;
}

.cine-theme {
  background: #CE1F64 !important;
  background-color: #CE1F64 !important;
}

.diseno-theme {
  background: #1E4881 !important;
  background-color: #1E4881 !important;
}

.gastronomia-theme {
  background: #EB7131 !important;
  background-color: #EB7131 !important;
}

.literatura-theme {
  background: #F2AB46 !important;
  background-color: #F2AB46 !important;
}

.musica-theme {
  background: #397E74 !important;
  background-color: #397E74 !important;
}

.facebook-theme {
  background: #134085 !important;
  background-color: #134085 !important;
}

.instagram-theme {
  background: #CE1F64 !important;
  background-color: #CE1F64 !important;
}

.youtube-theme {
  background: #f10e0e !important;
  background-color: #f10e0e !important;
}

body {
  background: #F8F8F8;
}

a {
  color: black;
}

a:hover {
  color: #CE1F64;
}

.nav-link {
  color: black;
}

input[type='radio']:checked {
  accent-color: #CE1F64 !important;
  border: 2px solid white;
}

input[type='text'] {
  border: 0px;
}

.nav-link:hover {
  color: white !important;
  background: #CE1F64;
}

.nav-link:focus {
  color: black;
}

.institutional-button {
  color: white;
  background: #CE1F64;
}

.nav-button:hover {
  color: white;
  border-radius: 4px;
  background: pink;
}

.background-color-gray-1 {
  background: #F8F8F8;
}

.background-color-gray-2 {
  background: #F2F2F2;
}

.background-color-white-1 {
  background: white;
}


.shadow-box-1 {
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.080);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-rigth {
  text-align: right;
}

.margin-20 {
  margin: 20px;
}

.margin-05-rem {
  margin: .5rem;
}

.margin-1-rem {
  margin: 1rem;
}

.margin-2-rem {
  margin: 2rem;
}

.width-25-rem{
  width: 25rem;
}

.foto-perfil {
  height: 250px;
  object-fit:cover;
  width: 250px;
  border-radius: 50%;
}

.foto-perfil-mini {
  height: 75px;
  object-fit:cover;
  width: 75px;
  border-radius: 50%;
}

.circle {
  border-radius: 50%;
}


.circle-little {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}

.circle-little-icon {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.grid-2 {
  display: grid;
  grid-template-columns: auto auto
}

.explorer-menu {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.listado {
  width: 100%;
  display: grid;
  grid-template-columns: 15% 85%
}

.resume-card {
  border-radius: 5%;
  margin-bottom: 20px;
  height: 320px;
}


.width-full {
  width: 100%;
}

.width-85-perc {
  width: 85%;
}

.width-60-perc {
  width: 60%;
}

.height-full {
  height: 100%;
}

.border-radius-8 {
  border-radius: 8px;
}

.white-pink {
  background: white;
  color: #CE1F64;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.cursos-pointer {
  cursor: pointer
}

.div-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.margin-auto {
  margin: auto;
}

.width-100 {
  width: 100;
}

.width-140 {
  width: 140px;
}

.width-200 {
  width: 200px;
}

.width-250 {
  width: 250px;
}

.width-300 {
  width: 300px;
}

.input-serch-size {
  width: 300px;
}

.space-arround {
  display: flex;
  justify-content: space-between;
}

.xl-icon {
  font-size: 30px;
  height: 30px ;
  width: 30px ;
}

.big-icon {
  font-size: 170px;
  height: 170px ;
  width: 170px ;
}

.small-icon {
  font-size: 75px;
  height: 75px ;
  width: 75px ;
}

.nano-icon {
  font-size: 60px;
  height: 60px ;
  width: 60px ;
}

.micro-icon {
  font-size: 25px;
  height: 25px ;
  width: 25px ;
}

.mat-fab .mat-button-wrapper{
  padding: 0px !important;
}

.btn {
  border: none;
}

.display-inline-block {
  display: inline-block;
}

h1 {
    font-size: 2.5rem!important;
}

h2 {
    font-size: 2rem!important;
}

h3 {
    font-size: 1.5rem!important;
}


.full-content {
  height: 300px;
  width: 100%;
}

.full-content img, video {
  width: 100%;
  height: 100%;
  object-fit:cover;
  margin: auto;
}

.return {
  position: absolute; top: 20px; left: 20px; cursor: pointer;
  font-size: x-large;
  color: white;
}




@media (max-width: 1300px) {
  .explorer-menu {
    display: grid;
    grid-template-columns: auto;
  }

  .input-serch-size {
    width: 200px;
  }

  .resume-card {
    border-radius: 5%;
    height: 350px;
  }

  .div-center-on-small {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


.firework {
	position: absolute;
  z-index: 9;
}
.explosion {
	position: absolute;
	left: -2px;
	bottom: 0;
	width: 4px;
	height: 80px;
	transform-origin: 50% 100%;
	/* background-color: rgba(0,0,0,.2); */
	overflow: hidden;
}
.explosion:nth-child(1) {
	transform: rotate(0deg) translateY(-15px);
}
.explosion:nth-child(2) {
	transform: rotate(30deg) translateY(-15px);
}
.explosion:nth-child(3) {
	transform: rotate(60deg) translateY(-15px);
}
.explosion:nth-child(4) {
	transform: rotate(90deg) translateY(-15px);
}
.explosion:nth-child(5) {
	transform: rotate(120deg) translateY(-15px);
}
.explosion:nth-child(6) {
	transform: rotate(150deg) translateY(-15px);
}
.explosion:nth-child(7) {
	transform: rotate(180deg) translateY(-15px);
}
.explosion:nth-child(8) {
	transform: rotate(210deg) translateY(-15px);
}
.explosion:nth-child(9) {
	transform: rotate(240deg) translateY(-15px);
}
.explosion:nth-child(10) {
	transform: rotate(270deg) translateY(-15px);
}
.explosion:nth-child(11) {
	transform: rotate(300deg) translateY(-15px);
}
.explosion:nth-child(12) {
	transform: rotate(330deg) translateY(-15px);
}

.explosion::before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	height: 40px;
	background-color: #f5cf52;
}

.celebration::before {
	background: rgb(217,25,25) !important;
  background: -moz-linear-gradient(90deg, rgba(217,25,25,1) 0%, rgba(232,156,22,1) 33%, rgba(205,193,50,1) 69%) !important;
  background: -webkit-linear-gradient(90deg, rgba(217,25,25,1) 0%, rgba(232,156,22,1) 33%, rgba(205,193,50,1) 69%) !important;
  background: linear-gradient(90deg, rgba(217,25,25,1) 0%, rgba(232,156,22,1) 33%, rgba(205,193,50,1) 69%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d91919",endColorstr="#cdc132",GradientType=1) !important;
}

@keyframes explosion {
	0% {
		top: 100%;
	}
	33%, 100% {
		top: -50%;
	}
}

#firework1 {
	left: 63%;
	top: -35%;
	transform: scale(1);
}
#firework1 .explosion::before {
	animation: explosion 2s .1s ease-in-out infinite;
}

#firework2 {
	left: 100%;
	top: 45%;
	transform: scale(.7);
}
#firework2 .explosion::before {
	animation: explosion 2s .6s ease-in-out infinite;
}

#firework3 {
	left: 60%;
	top: 125%;
	transform: scale(.4);
}

#firework3 .explosion::before {
	animation: explosion 2s .7s ease-in-out infinite;
}

#firework4 {
	left: -13%;
	top: -25%;
	transform: scale(1);
}
#firework4 .explosion::before {
	animation: explosion 2s .4s ease-in-out infinite;
}

#firework5 {
	left: 25%;
	top: 120%;
	transform: scale(.7);
}
#firework5 .explosion::before {
	animation: explosion 2s .3s ease-in-out infinite;
}

#firework6 {
	left: 20vmax;
	top: -15%;
	transform: scale(.4);
}

#firework6 .explosion::before {
	animation: explosion 2s .9s ease-in-out infinite;
}

#firework7 {
	left: -7vmax;
	top: 86%;
	transform: scale(1);
}
#firework7 .explosion::before {
	animation: explosion 2s .8s ease-in-out infinite;
}

button.rango {
}

.rango div {
  background-color: #CE1F64;
  color: white;
}


footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
}
